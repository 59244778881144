$( document ).ready(function() {
	/*enable tooltips everywhere (bootstrap.bundle.min.js - Popper)*/
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new bootstrap.Tooltip(tooltipTriggerEl)
	});


	/* TinyMCE */
	tinymce.init({
		selector: '.tinymce',
		height: 500,
		plugins: 'code preview autoresize importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
		editimage_cors_hosts: ['picsum.photos'],
		convert_urls: false,
		menubar: '',
		toolbar: 'undo redo | fullscreen template | bold italic underline strikethrough | blocks | alignleft aligncenter alignright | outdent indent |  numlist bullist |  code emoticons | image media link',
		toolbar_sticky: true,
		templates: [
			{ 
				title: 'Neuer Kunde',
				description: 'Neue Beschreibung',
				content: '<h2>Was macht der Kunde?</h2><p>...</p><h2>Was machen wir?</h2><p>...</p><h2>Wie ist die Kommunikation mit dem Kunden?</h2><p>...</p><h2>Ist etwas zu beachten?</h2><p>...</p>'
			},
		],
		template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
		template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
		image_caption: true,
		quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
		noneditable_class: 'mceNonEditable',
		toolbar_mode: 'sliding',
		contextmenu: 'link image table',
		content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
		language: 'de',
		browser_spellcheck: true,
		images_upload_url: '/api/v3/upload-img/',
		extended_valid_elements: 'img[class|src|border|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name|loading=lazy]',
	});


	$(".open-all-links").click(function() {
		var selected = [];
		$('.usefull-links .form-check-input:checked').each(function() {
			selected.push($(this).next().attr('href'));
		});

		//remove empty
		selected = selected.filter(item => item);

		jQuery.each(selected, function( i, val ) {
		  window.open(val);
		});
	});

	$(".usefull-links .list-group-item:nth-child(2) input:checkbox").click(function () {
		$(this).closest(".usefull-links").find("input:checkbox").prop('checked', $(this).prop('checked'));
	});

	//SELECT 2
	$('select:not(.no-select2)').select2({
		sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
	});

	$('.no-select2 select').select2('destroy');

	//Focus Filter Input
	$("#crm-table.tablesorter:not(.sf-toolbar-ajax-requests) thead td:nth-child(2) input").focus();
	//Focus also after Filter
	$(".filter-btn, .tablesorter-header").click(function() {
		$(".tablesorter:not(.sf-toolbar-ajax-requests) thead td:nth-child(2) input").focus();
	});

	$(".help-text-toggle").click(function() {
		$(".help-text").show();
	});

	$("#dashboard-links a").each(function(){
		var url = $(this).attr("href");
		$(this).attr("href", url + "?dashboard-link");
	});

	/* Clientoverview Search */
	$(document).on('keypress',function(e) {
		if(e.which == 13 && $('.tablesorter tbody tr:not(.filtered)').length == 1) {
			var client_link = $(".tablesorter tr:not(.filtered) .client-name a").attr("href");
			window.location.href = client_link;
		}
	});

	//Show Tooltip after 1sec after search AND 3sec after hide it
	$(document).on('keypress',function(e) {
		setTimeout(function(){
			const tooltips = document.querySelectorAll('.client-name a');
			//console.log(tooltips);

			if($('.tablesorter tbody tr:not(.filtered)').length == 1) {
				//Show Tooltip
				var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
				var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
					return new bootstrap.Tooltip(tooltipTriggerEl)
				});

				//Show Tooltip
				$("tr:not(.filtered) .client-name a").attr("title","Du kannst jetzt Enter drücken, um den Kunden zu öffnen.");
				var exampleTriggerEl = $("tr:not(.filtered) .client-name a");
				var tooltip = bootstrap.Tooltip.getInstance(exampleTriggerEl);
				tooltip.show();
				setTimeout(function(){
					tooltip.hide();
				}, 3000);
			}
		}, 1000);
	});

	/* Search Bar */
	//Start Search if selected
	$('#search-bar select').on('select2:select', function (e) {
		//console.log(e.params.data.id);
		window.location.href = "https://admin.hosono.de/clients/" + e.params.data.id;
	});

	//Start Serach if Button clicked
	$("#search-bar button").click(function() {
		var select = $("#search-bar option:selected").val();
		window.location.href = "https://admin.hosono.de/clients/" + select;
	});

	$('#search-bar select').on('select2:select', function (e) {
	    var data = e.params.data;
	    //console.log(data.id);
	});

	//Focus Search
	$(document).on('select2:open', () => {
		document.querySelector('.select2-search__field').focus();
	});

	//Open Search with s AND if not focus
	$(document).on('keyup',function(e) {
		if(e.keyCode == 83 && $("input").is(":focus") == false && $("textarea").is(":focus") == false) {
			$("#search-bar select").select2("open");
			document.querySelector('.select2-search__field').focus();
		}
	});
});