$( document ).ready(function() {
	// Tablesorter
	$("table:not(.no-sort)").each(function(){
		$(this).tablesorter({
			theme : "default",

			// widget code contained in the jquery.tablesorter.widgets.js file
			// use the zebra stripe widget if you plan on hiding any rows (filter widget)
			// the uitheme widget is NOT REQUIRED!
			widgets : [ "filter", "columns", "zebra", "saveSort"],

			ignoreCase: false,
			sortReset: true,
			widthFixed: false,

			widgetOptions : {
				// using the default zebra striping class name, so it actually isn't included in the theme variable above
				// this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
				zebra : ["even", "odd"],

				// class names added to columns when sorted
				columns: [ "primary", "secondary", "tertiary" ],

				// reset filters button
				filter_reset : ".reset",

				filter_liveSearch : true,

				// extra css class name (string or array) added to the filter element (input or select)
				filter_cssFilter: [
					'form-control form-control-sm',
					'form-control form-control-sm',
					'form-control form-control-sm',
					'form-control form-control-sm',
					'form-control form-control-sm',
					'form-control form-control-sm',
					'form-control form-control-sm'
				]

			}
		})
		.tablesorterPager({

			// target the pager markup - see the HTML block below
			container: $(".ts-pager"),

			// target the pager page select dropdown - choose a page
			cssGoto  : ".pagenum",
			size: 50,

			// remove rows from the table to speed up the sort of large tables.
			// setting this to false, only hides the non-visible rows; needed if you plan to add/remove rows with the pager enabled.
			removeRows: false,

			// output string - default is '{page}/{totalPages}';
			// possible variables: {page}, {totalPages}, {filteredPages}, {startRow}, {endRow}, {filteredRows} and {totalRows}
			output: '{startRow} - {endRow} / {filteredRows} ({totalRows})'		

		});
	});
	$('button[data-filter-column]').click(function() {
		/*** first method *** data-filter-column="1" data-filter-text="!son"
		  add search value to Discount column (zero based index) input */
		var filters = [],
		  $t = $(this),
		  col = $t.data('filter-column'), // zero-based index
		  txt = $t.data('filter-text') || $t.text(); // text to add to filter

		filters[col] = txt;
		// using "table.hasFilters" here to make sure we aren't targeting a sticky header
		$.tablesorter.setFilters( $('.tablesorter'), filters, true ); // new v2.9

		return false;
	  });
});